

















import { Component, Prop, Watch } from 'vue-property-decorator'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig, Navigation } from '../../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../../support/mixins/FiltersHandler.mixin.vue'

import { FilterBadges } from '../../../../molecules/FilterBadges/FilterBadges.vue'
import { FilterPopover } from '../FilterPopover/FilterPopover.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FilterPopoverList>({
  name: 'FilterPopoverList',
  components: { FilterBadges, FilterPopover }
})
export class FilterPopoverList extends FiltersHandlerMixin {
  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: false, type: Array })
  public navData?: Navigation[]

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public onClearFilters () {
    this.filters = this.clearFilters(this.params, this.filters)
  }

  public onFilterChange (queryParam: string, newFilter: string) {
    this.filters = this.updateFilter(this.filters, queryParam, newFilter)
  }

  public onRemoveFilter (queryParam: string, value: string) {
    this.filters = this.removeFilter(this.filters, this.params, queryParam, value)
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }
}

export default FilterPopoverList
